import React from 'react';
import { motion } from 'framer-motion';

function Contact() {
  return (
    <section id="section-contact" className="flex flex-col items-center text-center py-16 px-4 w-full max-w-4xl mx-auto text-white">
      <h2 className="text-3xl font-bold mb-8">Contáctenos</h2>
      <div className="bg-white rounded-lg shadow-lg p-8 w-full max-w-md flex flex-col items-center">
        <a href="https://wa.me/56976840528" className="inline-block mb-6">
          <motion.img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/1200px-WhatsApp.svg.png"
            alt="WhatsApp Logo"
            className="w-20 h-20"
            animate={{ y: [0, -10, 0] }}
            transition={{ duration: 2, repeat: Infinity, ease: "easeInOut" }}
          />
        </a>
        <a
          href="https://wa.me/56976840528"
          className="inline-block px-6 py-3 text-lg font-semibold text-white bg-[#25D366] rounded-full transition duration-300 ease-in-out transform hover:bg-[#1ebc57] hover:scale-105 active:bg-[#239D53] active:scale-95 focus:outline-none focus:ring-2 focus:ring-[#25D366] focus:ring-opacity-50"
          target="_blank"
          rel="noopener noreferrer"
        >
          ¡Contáctanos en WhatsApp!
        </a>
      </div>
    </section>
  );
}

export default Contact;
