import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';

const colorTranslation = {
    blue: 'Azul',
    green: 'Verde',
    pink: 'Rosado',
};

const CartItem = ({ item, index, handleQuantityChange, handleRemoveFromCart, determinePriceType, totalPriceForItem }) => {
    const [inputQuantity, setInputQuantity] = useState(item.quantity || 1);
    const [priceType, setPriceType] = useState(determinePriceType(item.quantity));

    if (!item || !item.product || !item.color || typeof item.quantity === 'undefined') {
        return null;
    }

    // Aquí se obtiene la imagen del producto
    const productImage = item.imageUrl; // Asegúrate de que esta propiedad contenga la URL de la imagen

    const handleInputChange = (e) => {
        const value = e.target.value; // Obtén el valor como cadena

        setInputQuantity(value); // Actualiza el estado del input con el valor actual

        const parsedValue = parseInt(value, 10); // Intenta convertirlo a un número
        if (!isNaN(parsedValue) && parsedValue > 0) {
            handleQuantityChange(index, parsedValue - item.quantity);
            setPriceType(determinePriceType(parsedValue));
        } else if (value === '') {
            // Si el campo está vacío, puedes manejarlo aquí si lo necesitas
            setPriceType(determinePriceType(0)); // O manejar el tipo de precio como desees
        }
    };

    const translatedColor = colorTranslation[item.color] || item.color;
    const priceTypeMapping = {
        pallet: "Pale",
        wholesale: "Por mayor",
        unit: "Unidad",
    };

    return (
        <div className="flex items-center justify-between py-4 border-b">
            <div className="w-16 h-16 flex-shrink-0 mr-4">
                {productImage && (
                    <img
                        src={productImage} // Aquí se utiliza la URL de la imagen directamente
                        alt={`${item.product} - ${translatedColor}`} // Texto alternativo para mejor accesibilidad
                        className="w-full h-full object-cover rounded-lg"
                    />
                )}
            </div>
            <div className="flex flex-col flex-grow">
                <p className="text-sm font-bold">{item.product}</p>
                <p className="text-sm">Color: {translatedColor}</p>
                <p className="text-sm">Tipo: {priceTypeMapping[priceType] || "Unidad"}</p>
                <p className="text-sm font-bold">Total: {totalPriceForItem(item)} CLP</p> {/* Muestra totalPriceForItem */}
                <input
                    type="number"
                    value={inputQuantity}
                    onChange={handleInputChange}
                    min="0"
                    className="w-16 text-center border border-gray-300 rounded-md"
                />
            </div>
            <button
                className="text-red-500 hover:text-red-700"
                onClick={() => handleRemoveFromCart(index)}
            >
                <FaTimes />
            </button>
        </div>
    );
};

export default CartItem;
