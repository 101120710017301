import React, { useState } from 'react';
import { motion } from 'framer-motion';
import productImages from '../productImages.json';

export default function ProductCard({
  product,
  hoveredIndex,
  index,
  setHoveredIndex,
  handleAddToCart,
}) {
  const productColors = Object.keys(productImages[product]);
  const prices = productImages[product][productColors[0]].prices;

  // Convertir precios a números, manejando el caso de "-"
  const unitPrice = prices.unit !== "-" ? Number(prices.unit) : 0;
  const wholesalePrice = prices.wholesale !== "-" ? Number(prices.wholesale) : 0;
  const palletPrice = prices.pallet !== "-" ? Number(prices.pallet) : 0;

  const [selectedColor, setSelectedColor] = useState(productColors[0]); // Default to the first color

  return (
    <motion.div
      className="p-4 bg-white shadow-lg rounded-lg"
      onMouseEnter={() => setHoveredIndex(index)}
      onMouseLeave={() => setHoveredIndex(null)}
    >
      {/* Product name displayed above prices */}
      <h3 className="text-lg font-bold text-center">{product}</h3>
      
      <img
        src={productImages[product][selectedColor].image} // Use selected color image
        alt={product}
        className="w-full h-48 object-contain"
      />
      
      <div className="mt-4">
        <div className="flex justify-center space-x-2">
          {productColors.map((color) => (
            <button
              key={color}
              className={`w-6 h-6 rounded-full ${selectedColor === color ? 'ring-2 ring-black' : ''}`}
              style={{ backgroundColor: color }}
              onClick={() => setSelectedColor(color)} // Change only this card's color
            />
          ))}
        </div>
        
        {/* Contenedor de precios mejorado */}
        <div className="mt-4 bg-gray-100 p-4 rounded-lg shadow-sm">
          <h4 className="text-md font-semibold text-center">Precios</h4>
          <p className="text-sm text-center">Unidad: <span className="font-bold">${unitPrice} CLP</span></p>
          <p className="text-sm text-center">Mayor (+60 und): <span className="font-bold">${wholesalePrice} CLP</span></p>
          <p className="text-sm text-center">Pale (+240 und): <span className="font-bold">${palletPrice} CLP</span></p>
        </div>

        <button
          className="mt-4 bg-green-500 text-white py-2 px-4 rounded-lg mx-auto block"
          onClick={() => handleAddToCart(product, selectedColor, 'unit')}
        >
          Agregar al Carrito
        </button>
      </div>
    </motion.div>
  );
}
