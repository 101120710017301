import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

function Welcome() {
  const [fontSize, setFontSize] = useState('text-4xl');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setFontSize('text-2xl'); // Tamaño de letra más pequeño para móviles
      } else {
        setFontSize('text-4xl'); // Tamaño de letra para pantallas grandes
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Ejecutar inicialmente para establecer el tamaño correcto

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <section
      id="section-welcome"
      className="flex flex-col justify-center items-center w-full h-screen relative overflow-hidden"
    >
      <img
        src="background.jpg"
        alt="Background"
        className="w-full h-full object-cover"
      />
      <motion.div
        className={`absolute font-bold text-white ${fontSize}`}
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.5 }}
      >
        Bienvenidos a FBS
      </motion.div>
    </section>
  );
}

export default Welcome;
