import React, { useState, useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import { FaShoppingCart } from 'react-icons/fa';
import productImages from '../productImages.json';
import ProductCard from './ProductCart';
import Cart from './Cart';
import { motion } from 'framer-motion';

export default function Products() {
  const productList = Object.keys(productImages);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [cart, setCart] = useState([]);
  const [isVibrating, setIsVibrating] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const initialColor = "#007A33"; 
  const notificationColor = "#FFA500"; 

  // Recuperar carrito del localStorage al cargar la página
  useEffect(() => {
    const savedCart = localStorage.getItem('cart');
    if (savedCart) {
      setCart(JSON.parse(savedCart));
    }
  }, []);

  // Guardar carrito en localStorage cuando cambia
  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  const handleAddToCart = (product, color, purchaseType, price) => {
    if (isAnimating) return;

    const existingItemIndex = cart.findIndex(
      (item) => item.product === product && item.color === color && item.purchaseType === purchaseType
    );

    const imageUrl = productImages[product][color].image; // Obtener la URL de la imagen

    if (existingItemIndex > -1) {
      const updatedCart = [...cart];
      updatedCart[existingItemIndex].quantity += 1;
      setCart(updatedCart);
    } else {
      setCart([...cart, { product, color, quantity: 1, purchaseType, imageUrl }]); // Agregar la URL de la imagen al carrito
    }

    setIsAnimating(true);
    setIsVibrating(true);
    setTimeout(() => {
      setIsVibrating(false);
      setIsAnimating(false);
    }, 700);
  };

  const handleRemoveFromCart = (index) => {
    const updatedCart = cart.filter((_, i) => i !== index);
    setCart(updatedCart);
  };

  const handleQuantityChange = (index, change) => {
    const updatedCart = [...cart];
    updatedCart[index].quantity = Math.max(1, updatedCart[index].quantity + change);
    setCart(updatedCart);
  };

  const handleRemoveAllFromCart = () => {
    setCart([]);
  };

  const determinePriceType = (quantity) => {
    if (quantity >= 240) {
      return "pallet";
    } else if (quantity >= 60) {
      return "wholesale";
    } else {
      return "unit";
    }
  };

  const totalPriceForItem = (item) => {
    const product = productImages[item.product]?.[item.color];
    if (!product) return 0;

    const priceType = determinePriceType(item.quantity);
    const unitPrice = Number(product.prices[priceType]) || 0;

    return unitPrice * item.quantity; 
  };

  const totalPrice = cart.reduce((sum, item) => sum + totalPriceForItem(item), 0);
  const totalItems = cart.reduce((sum, item) => sum + item.quantity, 0);

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4 mt-16 text-center text-white">Productos</h2>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {productList.map((product, index) => (
          <ProductCard
            key={product}
            product={product}
            hoveredIndex={hoveredIndex}
            index={index}
            setHoveredIndex={setHoveredIndex}
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
            handleAddToCart={handleAddToCart}
          />
        ))}
      </div>

      <AnimatePresence>
        {isCartOpen && (
          <Cart
            cart={cart}
            handleQuantityChange={handleQuantityChange}
            handleRemoveFromCart={handleRemoveFromCart}
            totalPrice={totalPrice}
            setIsCartOpen={setIsCartOpen}
            handleRemoveAllFromCart={handleRemoveAllFromCart}
            determinePriceType={determinePriceType}
            totalPriceForItem={totalPriceForItem}
          />
        )}
      </AnimatePresence>

      <motion.button
        className="fixed bottom-4 right-4 z-10 text-white py-3 px-6 rounded-full shadow-lg transition duration-300 flex items-center"
        onClick={() => setIsCartOpen(!isCartOpen)}
        animate={
          isVibrating
            ? {
                backgroundColor: [initialColor, notificationColor, notificationColor, initialColor],
                scale: [1, 1.1, 0.9, 1]
              }
            : { backgroundColor: initialColor }
        }
        transition={{ duration: 0.8 }}
      >
        <FaShoppingCart className="mr-2" /> Carrito ({totalItems})
      </motion.button>
    </div>
  );
}
