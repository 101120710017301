import React from 'react';
import CartItem from './CartItem';
import { motion } from 'framer-motion';
import { FaTrash } from 'react-icons/fa';

export default function Cart({
  cart,
  handleQuantityChange,
  handleRemoveFromCart,
  totalPrice,
  setIsCartOpen,
  handleRemoveAllFromCart,
  determinePriceType,
  totalPriceForItem
}) {

  const colorTranslation = {
    blue: 'Azul',
    green: 'Verde',
    pink: 'Rosado',
  };

  const priceTypeMapping = {
    pallet: "Pale",
    wholesale: "Por mayor",
    unit: "Unidades",
  };
  const handleFinalizePurchase = () => {
    // Verifica si el carrito está vacío
    if (cart.length === 0) {
      alert("Tu carrito está vacío. Agrega productos antes de finalizar la compra.");
      return; // Sale de la función si el carrito está vacío
    }

    // Calcula el total de precios
    const totalPrice = cart.reduce((sum, item) => sum + totalPriceForItem(item), 0);

    const message = cart.map(item => {
      const product = `${item.product} (${colorTranslation[item.color]})`;
      const price = totalPriceForItem(item);
      const type = priceTypeMapping[determinePriceType(item.quantity)];
      return `- ${product}: [${item.quantity}/${type}]  $${price}`;
    }).join('\n');

    // Agrega el total al final del mensaje
    const totalMessage = `\n\nTotal: $${totalPrice}`;
    const finalMessage = message + totalMessage;

    // Encode the message for URL
    const encodedMessage = encodeURIComponent(finalMessage);

    // Construct the WhatsApp URL with the encoded message
    const whatsappUrl = `https://wa.me/56976840528/?text=${encodedMessage}`;

    // Open WhatsApp in a new tab
    window.open(whatsappUrl, '_blank');
  };


  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 50 }}
      className="fixed bottom-4 right-4 z-20 bg-white rounded-lg shadow-lg p-4 w-96"
    >
      <h3 className="text-lg font-bold mb-4 flex justify-between items-center">
        Carrito de Compras
        <button
          className="text-red-500 hover:text-red-600"
          onClick={handleRemoveAllFromCart}
        >
          <FaTrash size={20} />
        </button>
      </h3>
      <div className="overflow-y-auto max-h-64 mb-4">
        {cart.length > 0 ? (
          cart.map((item, index) => (
            <CartItem
              key={index}
              item={item}
              index={index}
              handleQuantityChange={handleQuantityChange}
              handleRemoveFromCart={handleRemoveFromCart}
              determinePriceType={determinePriceType}
              totalPriceForItem={totalPriceForItem}
            />
          ))
        ) : (
          <p className="text-center text-gray-500">El carrito está vacío.</p>
        )}
      </div>
      <div className="flex justify-between items-center mb-4">
        <p className="text-lg font-bold">Total: ${totalPrice} CLP</p>
      </div>
      <div className="flex justify-between">
        <button
          className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600"
          onClick={handleFinalizePurchase} // Cambia aquí para finalizar la compra
        >
          Finalizar Compra en WhatsApp
        </button>
        <button
          className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600"
          onClick={() => setIsCartOpen(false)}
        >
          Cerrar
        </button>
      </div>
    </motion.div>
  );
}
