import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Menu, X } from 'lucide-react';
import { Link } from 'react-router-dom'; // Importar Link de react-router-dom

const navItems = [
  { text: 'Bienvenidos', path: '/' },
  { text: 'Productos', path: '/products' },
  { text: '¿Quiénes somos?', path: '/about' },
  { text: 'Contáctenos', path: '/contact' },
];

// Hook para obtener el tamaño de la ventana
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: typeof window !== 'undefined' ? window.innerWidth : 0,
    height: typeof window !== 'undefined' ? window.innerHeight : 0,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

// Estilos para el Header
const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  background: linear-gradient(to right, #2c3e50, #34495e);
  padding: 1rem;
  box-sizing: border-box;
  z-index: 1000;
  
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
`;

const Logo = styled.img`
  width: ${({ isMobile }) => (isMobile ? '30vw' : '8vw')};
  height: auto;
  transition: width 0.3s ease;
`;

const NavContainer = styled.nav`
  display: ${({ isMobile, isMenuOpen }) => (isMobile ? (isMenuOpen ? 'flex' : 'none') : 'flex')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  align-items: ${({ isMobile }) => (isMobile ? 'flex-end' : 'center')};
  background: ${({ isMobile }) => (isMobile ? 'rgba(44, 62, 80, 0.95)' : 'transparent')};
  position: ${({ isMobile }) => (isMobile ? 'absolute' : 'static')};
  top: ${({ isMobile }) => (isMobile ? '100%' : 'auto')};
  left: 0;
  right: 0;
  padding: ${({ isMobile }) => (isMobile ? '1rem' : '0')};
  transition: all 0.3s ease;
  text-align: right;
`;

const NavList = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
`;

const NavItem = styled.li`
  margin: ${({ isMobile }) => (isMobile ? '0.5rem 0' : '0 0.5rem')};
`;

const NavLink = styled(Link)`  // Usar Link en lugar de a
  text-decoration: none;
  font-size: ${({ isHovered }) => (isHovered ? '1.4rem' : '1.2rem')};
  color: ${({ isHovered }) => (isHovered ? '#FFC107' : '#f0f0f0')};
  font-weight: 500;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
  margin: 1rem;

  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #FFC107;
    transition: width 0.3s ease;
  }

  &:hover::after {
    width: 100%;
  }
`;

const MenuIcon = styled.div`
  display: ${({ isMobile }) => (isMobile ? 'block' : 'none')};
  cursor: pointer;
  color: #f0f0f0;
`;

export default function Header() {
  const [hoveredItem, setHoveredItem] = useState(null);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const size = useWindowSize();
  const isMobile = size.width <= 768;

  const handleMouseEnter = (index) => setHoveredItem(index);
  const handleMouseLeave = () => setHoveredItem(null);
  const handleMenuToggle = () => setMenuOpen(!isMenuOpen);

  return (
    <HeaderContainer>
      <HeaderContent>
        <NavLink to={"/"}>
          <Logo src="/Fbs.png" alt="FBS Logo" isMobile={isMobile} />
        </NavLink>
        <MenuIcon isMobile={isMobile} onClick={handleMenuToggle}>
          {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
        </MenuIcon>
        <NavContainer isMobile={isMobile} isMenuOpen={isMenuOpen}>
          <NavList isMobile={isMobile}>
            {navItems.map((item, index) => (
              <NavItem key={item.text} isMobile={isMobile}>
                <NavLink
                  to={item.path} // Cambia el href por to
                  isHovered={index === hoveredItem}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => setMenuOpen(false)}
                >
                  {item.text}
                </NavLink>
              </NavItem>
            ))}
          </NavList>
        </NavContainer>
      </HeaderContent>
    </HeaderContainer>
  );
}
