import React from 'react';
import "../App.css"
function About() {
  return (
    <div className="bg-[#2c3e50] text-white py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <h2 className="text-3xl font-bold text-center mb-12">¿Quiénes Somos?</h2>
        <section id="section-about" className="space-y-12">
          <div className="flex justify-center">
            <img 
              src="Logo.png" 
              alt="FBS Logo" 
              className="w-48 h-48 object-cover transition-transform duration-300 hover:scale-105"
            />
          </div>
          <p className="text-lg text-center max-w-2xl mx-auto leading-relaxed">
            En <strong className="text-[#3498db]">FBS</strong>, comenzamos nuestra trayectoria ofreciendo detergentes de alta calidad, dedicados a la limpieza efectiva y ecológica. Con el tiempo, hemos expandido nuestra gama de productos para incluir una variedad de soluciones de aseo, manteniendo siempre nuestro compromiso con la excelencia y la satisfacción del cliente.
          </p>
          <div className="text-center">
            <h3 className="text-xl font-semibold mb-6">Descarga nuestras fichas técnicas:</h3>
            <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
              <PdfButton href="/FBS1.pdf" text="Ficha Técnica FBS" />
              <PdfButton href="/FBS2.pdf" text="Hoja de seguridad FBS" />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

function PdfButton({ href, text }) {
  return (
    <a
      href={href}
      className="inline-block px-6 py-3 bg-[#3498db] text-white font-semibold rounded-lg transition duration-300 ease-in-out transform hover:bg-[#2980b9] hover:scale-105 active:bg-[#2471a3] active:scale-95 focus:outline-none focus:ring-2 focus:ring-[#3498db] focus:ring-opacity-50"
      target="_blank"
      rel="noopener noreferrer"
    >
      {text}
    </a>
  );
}

export default About;