import React from 'react';
import Header from './components/Header';
import Welcome from './components/Welcome';
import Products from './components/Products';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className="flex flex-col justify-between items-center min-h-screen overflow-x-hidden bg-[#2c3e50]">
        <Header />
        <div className="flex flex-col items-center w-full flex-grow mt-[65px] overflow-y-auto">
          <Routes>
            <Route path="/" element={<Welcome />} />
            <Route path="/products" element={<Products />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
